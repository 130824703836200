import {
  anthropicPriceDict,
  type AnthropicModel,
  type TAnthropicOptimizationsType
} from "@toolflow/shared";
import React, { FC } from "react";
import useUpdateField from "../../../../../useUpdateField";
import useCurrentAndPrevOptmizationsSync from "../../../../../hooks/useCurrentAndPrevOptmizationsSync";
import type { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import formatNumberToLocale from "../../../../../../../../../utilities/formatters/numbers/formatNumberToLocale";
import { ListItemText } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { anthropicInitialOptimizations } from "../../../../../context/initialStates";
interface IAnthrpoicOptimizationsProps {
  id: string;
  anthropicOptimizations: TAnthropicOptimizationsType;
}

interface State {
  llmModel: AnthropicModel;
}
const AnthropicOptimizations: FC<IAnthrpoicOptimizationsProps> = (props) => {
  let anthropicOptimizations: TAnthropicOptimizationsType =
    anthropicInitialOptimizations;
  const { id, anthropicOptimizations: anthropicOptimizationsProps } = props;
  if (!isEmpty(anthropicOptimizationsProps)) {
    anthropicOptimizations = anthropicOptimizationsProps;
  }
  const updateField = useUpdateField(id);
  useCurrentAndPrevOptmizationsSync({ optimizations: anthropicOptimizations });

  const { llmModel, temperature } = anthropicOptimizations;

  const handleTemperatureChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    updateField(
      typeof newValue === "object" ? newValue[0] : newValue,
      "anthropicOptimizations.temperature"
    );
  };

  const updateStateValue =
    (kString: keyof State) => (event: SelectChangeEvent<string>) => {
      updateField(event.target.value, `anthropicOptimizations.${kString}`);
    };

  return (
    <FormGroup>
      <Typography id="creativity-slider">Temperature</Typography>
      <Slider
        className="nowheel nodrag nopan"
        value={temperature}
        onChange={handleTemperatureChange}
        marks={[{ value: 50 }]}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="llmModel-label">Model</InputLabel>
        <Select
          labelId="llmModel-label"
          id="llmModel"
          value={llmModel}
          label="Model"
          className="nowheel nodrag nopan"
          onChange={updateStateValue("llmModel")}
        >
          {Object.entries(anthropicPriceDict).map(([k, v]) => (
            <MenuItem value={k} key={k}>
              <ListItemText>{k}</ListItemText>
              <Typography variant="body2" color="text.secondary">
                {formatNumberToLocale(v.tokenCount)} tokens
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FormGroup>
  );
};

export default AnthropicOptimizations;
