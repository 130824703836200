import { ListItemText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import type { SelectChangeEvent } from "@mui/material/Select";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { openAIPriceDict, type OptimizationsType } from "@toolflow/shared";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import formatNumberToLocale from "../../../../../../../../../utilities/formatters/numbers/formatNumberToLocale";
import useUpdateField from "../../../../../useUpdateField";

interface State {
  errorMessage: string;
  reducerId: string;
  promptId: string;
  prompt: string;
  llmModel: string;
  temperature: number;
  outputData: string;
}

function Optimizations({
  id,
  optimizations
}: {
  id: string;
  optimizations: OptimizationsType;
}) {
  const updateField = useUpdateField(id);

  const [prevOptimizations, setPrevOptimizations] = useState(optimizations);
  useEffect(() => {
    // Perform deep equality check
    if (!isEqual(optimizations, prevOptimizations)) {
      // ToolInputFields have changed, trigger re-render
      // Update the previous toolInputFields value
      setPrevOptimizations(optimizations);
    }
  }, [optimizations]);

  const { llmModel, temperature } = optimizations;

  const handleTemperatureChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    updateField(
      typeof newValue === "object" ? newValue[0] : newValue,
      "optimizations.temperature"
    );
  };

  const updateStateValue =
    (kString: keyof State) => (event: SelectChangeEvent<string>) => {
      updateField(event.target.value, `optimizations.${kString}`);
    };

  return (
    <FormGroup>
      <Typography id="creativity-slider">Temperature</Typography>
      <Slider
        className="nowheel nodrag nopan"
        value={temperature}
        onChange={handleTemperatureChange}
        marks={[{ value: 50 }]}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="llmModel-label">Model</InputLabel>
        <Select
          labelId="llmModel-label"
          id="llmModel"
          value={llmModel}
          label="Model"
          className="nowheel nodrag nopan"
          onChange={updateStateValue("llmModel")}
        >
          {Object.entries(openAIPriceDict).map(([k, v]) => (
            <MenuItem value={k} key={k}>
              <ListItemText>{k}</ListItemText>
              <Typography variant="body2" color="text.secondary">
                {formatNumberToLocale(v.tokenCount)} tokens
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FormGroup>
  );
}

export default Optimizations;
