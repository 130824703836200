// chatSlice.ts
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import {
  EMessageRole,
  EMessageStatus,
  type TOpenAIMessageSettings,
  type TMessageByThreadId
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";

interface IChatInitialState {
  userMessage: TMessageByThreadId;
  messages: TMessageByThreadId[];
  isLoading: boolean; // when messages are being received from the agent
  actionButtonDisabled: boolean; // when we can't send, button we aren't receiving messages from the agent (e.g. reseting thread)
}

const initialState: IChatInitialState = {
  userMessage: {
    id: "",
    message: "",
    role: EMessageRole.USER,
    settings: {
      runId: null,
      incompleteDetails: null,
      status: EMessageStatus.COMPLETED
    }
  },
  messages: [],
  isLoading: false,
  actionButtonDisabled: false
};

export type TChatReducerName = "chat";
export const CHAT_REDUCER_NAME: TChatReducerName = "chat";

const chatSlice = createSlice({
  name: CHAT_REDUCER_NAME,
  initialState,
  reducers: {
    resetChatState() {
      return initialState;
    },
    setMessages(state, action: PayloadAction<TMessageByThreadId[]>) {
      state.messages = action.payload;
    },
    // finalizes the message when the agent is done
    completeSocketMessage(
      state,
      action: PayloadAction<{ id: string; settings: TOpenAIMessageSettings }>
    ) {
      const index = state.messages.findIndex(
        (message) => message.id === action.payload.id
      );
      const message = state.messages[index];
      state.messages[index] = {
        ...message,
        settings: action.payload.settings
      };
    },
    // updates the message text
    updateMessagesWithNewSocketMessage(
      state,
      action: PayloadAction<{ id: string; message: string }>
    ) {
      const index = state.messages.findIndex(
        (message) => message.id === action.payload.id
      );
      if (index === -1) {
        if (state.messages[state.messages.length - 1]?.role === "user") {
          state.messages.push({
            role: EMessageRole.ASSISTANT,
            message: action.payload.message,
            id: action.payload.id,
            isLastMessage: false,
            settings: {
              status: EMessageStatus.IN_PROGRESS,
              incompleteDetails: null,
              runId: null
            }
          });
        } else {
          state.messages[state.messages.length - 1] = {
            ...state.messages[state.messages.length - 1],
            message: action.payload.message,
            id: action.payload.id
          };
        }
      } else {
        const message = state.messages[index];
        message.message += action.payload.message;
        state.messages[index] = {
          ...message,
          message: message.message
        };
      }
    },
    // handles loading status of the tools
    updateLastMessageSettings(
      state,
      action: PayloadAction<{
        settings: TOpenAIMessageSettings;
        id?: string;
      }>
    ) {
      state.messages.push({
        role: EMessageRole.ASSISTANT,
        message: "",
        id: action.payload.id || uuidv4(),
        isLastMessage: false,
        settings: action.payload.settings
      });
    },
    updateMessagesWithNewUserMessage(
      state,
      action: PayloadAction<TMessageByThreadId>
    ) {
      state.messages.push(action.payload);
    },
    setThreadMessagesLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setActionButtonDisabled(state, action: PayloadAction<boolean>) {
      state.actionButtonDisabled = action.payload;
    }
  }
});

export const {
  setActionButtonDisabled,
  resetChatState,
  setMessages,
  completeSocketMessage,
  updateMessagesWithNewUserMessage,
  updateMessagesWithNewSocketMessage,
  updateLastMessageSettings,
  setThreadMessagesLoading
} = chatSlice.actions;

export default chatSlice.reducer;
