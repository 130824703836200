import React from "react";
import { useForm, FormProvider } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import useDefaultAgentFormValues from "../hooks/useDefaultAgentFormValues";
import { visibilitySchema } from "../../../../../utilities/validation/zod/visibilitySchema";
import tagSchema from "../../../../../utilities/validation/zod/ts";
import {
  AnthropicModel,
  OpenAiTextModel,
  type TAgentDataToSave
} from "@toolflow/shared";
import { AGENT_NAME_REQUIRED_ERROR } from "../constants/errors";

const schema = z.object({
  name: z.string().min(1, { message: AGENT_NAME_REQUIRED_ERROR }),
  description: z.string().min(0),
  visibility: visibilitySchema,
  tag: tagSchema,
  config: z.object({
    instructions: z.any(),
    model: z.union([
      z.nativeEnum(OpenAiTextModel),
      z.nativeEnum(AnthropicModel) // Replace YourSecondEnum with your other enum
    ]),
    temperature: z.number(),
    toolIds: z.array(z.any())
  })
});

const AgentFormProvider = ({ children }: { children: React.ReactNode }) => {
  const defaultValues = useDefaultAgentFormValues();

  const methods = useForm<TAgentDataToSave>({
    defaultValues: defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange"
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default AgentFormProvider;
