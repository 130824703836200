import { OpenAiTextModel, AnthropicModel, PerplexityModel } from "./LLMModels";

export type TOpenAIPriceDictType = {
  [key in OpenAiTextModel]: {
    tokenCount: number;
    label: string;
    skip?: boolean;
  };
};
export type TAnthropicPriceDictType = {
  [key in AnthropicModel]: {
    tokenCount: number;
    label: string;
  };
};
export type TPerplexityPriceDictType = {
  [key in PerplexityModel]: {
    tokenCount: number;
    label: string;
    skip?: boolean;
  };
};
// amount is input token max count , also referred in backend in TokenLengthValidation

export const openAIPriceDict: TOpenAIPriceDictType = {
  [OpenAiTextModel.GPT35]: {
    tokenCount: 16000,
    label: "GPT-3.5"
  },
  [OpenAiTextModel.GPT4]: {
    tokenCount: 8000,
    label: "GPT-4"
  },
  [OpenAiTextModel.GPT4Turbo]: {
    tokenCount: 128000,
    label: "GPT-4 Turbo"
  },
  [OpenAiTextModel.GPT4o]: {
    tokenCount: 128000,
    label: "GPT-4o"
  },
  [OpenAiTextModel.GPT4o_Aug]: {
    tokenCount: 128000,
    label: "GPT-4o Aug",
    skip: true
  }
  // "o1-mini": {
  //   tokenCount: 128000
  // },
  // "o1-preview": {
  //   tokenCount: 128000
  // }
};

export const anthropicPriceDict: TAnthropicPriceDictType = {
  [AnthropicModel.OPUS]: {
    tokenCount: 200000,
    label: "Claude 3 Opus"
  },
  [AnthropicModel.SONNET]: {
    tokenCount: 200000,
    label: "Claude 3 Sonnet"
  },
  [AnthropicModel.SONNET_3_5]: {
    tokenCount: 200000,
    label: "Claude 3.5 Sonnet"
  },
  [AnthropicModel.HAIKU]: {
    tokenCount: 200000,
    label: "Claude 3 Haiku"
  }
};

export const perplexityPriceDict: TPerplexityPriceDictType = {
  [PerplexityModel.SonarSmOnline]: {
    tokenCount: 128000,
    label: "Perplexity · Sonar SM"
  },
  [PerplexityModel.SonarLgOnline]: {
    tokenCount: 128000,
    label: "Perplexity · Sonar LG"
  },
  [PerplexityModel.SonarHgOnline]: {
    tokenCount: 128000,
    label: "Perplexity · Sonar HG"
  },
  [PerplexityModel.SonarSmChat]: {
    tokenCount: 128000,
    label: "Perplexity · Sonar SM Chat",
    skip: true
  },
  [PerplexityModel.SonarLgChat]: {
    tokenCount: 128000,
    label: "Perplexity · Sonar LG Chat",
    skip: true
  }
};

export function isOpenAITextModel(model: string): model is OpenAiTextModel {
  return Object.keys(openAIPriceDict).includes(model);
}

export function isAnthropicModel(model: string): model is AnthropicModel {
  return Object.keys(anthropicPriceDict).includes(model);
}

export function isPerplexityModel(model: string): model is PerplexityModel {
  return Object.keys(perplexityPriceDict).includes(model);
}
