import { AnthropicModel, type UpdateSType } from "@toolflow/shared";
import { useCallback } from "react";

interface ModelTemperatureConfig {
  updateField: (value: UpdateSType, path: string) => void;
  temperatureFieldKey: string;
  currentModel?: string;
}

export const useModelTemperatureLogic = ({
  updateField,
  temperatureFieldKey,
  currentModel
}: ModelTemperatureConfig) => {
  const handleModelChange = useCallback(
    (value: string) => {
      if (Object.values(AnthropicModel).includes(value as AnthropicModel)) {
        // Check if temperature is over 50 (Anthropic's max)
        updateField(50, temperatureFieldKey);
      }
    },
    [updateField, temperatureFieldKey]
  );

  const getMarksMax = () =>
    Object.values(AnthropicModel).includes(currentModel as AnthropicModel)
      ? 50
      : undefined;

  return {
    handleModelChange,
    getMarksMax
  };
};
