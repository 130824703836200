import { HydratedDocument } from "mongoose";
import {
  AgentSchemaId,
  OrganizationSchemaId,
  ThreadSchemaId,
  UserSchemaId
} from "../schemaTypes";
import { LeanDocument } from "./utilities";

export enum EThreadType {
  OpenAI = "OpenAIThread",
  Anthropic = "AnthropicThread"
}

export const EThreadArray: readonly EThreadType[] = [
  EThreadType.OpenAI,
  EThreadType.Anthropic
] as const;

export type TThread = "OpenAIThread";
export type TThreadReference = {
  _id: string;
  name: string;
  userId: string;
  organizationId?: string;
  externalId?: string;
  agentId: string;
  type: EThreadType;
  assistantId: string;
  messageCount: number;
  createdAt: number;
  updatedAt: number;
};

type PropsWithSchemaId = {
  _id: ThreadSchemaId;
  userId: UserSchemaId;
  organizationId?: OrganizationSchemaId;
  assistantId: AgentSchemaId;
};

export type TThreadReferenceDocument = HydratedDocument<
  TThreadReference,
  PropsWithSchemaId
>;

export type TCreateThreadReferenceDocumentProps = {
  name: string;
  userId: UserSchemaId;
  organizationId?: OrganizationSchemaId;
  externalId?: string;
  assistantId: AgentSchemaId;
};

export type TUpdateThreadReferenceDocumentProps =
  Partial<TCreateThreadReferenceDocumentProps>;

export type TLeanThreadResponse = LeanDocument<
  TThreadReference,
  PropsWithSchemaId
>;
