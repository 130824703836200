import type {
  GetUserThreadsResponse,
  TGetThreadMessagesResponse,
  TGetThreadRunStepsByIdResponse,
  TResetThreadResponse,
  TThreadReference
} from "@toolflow/shared";
import { setErrorMessage } from "../../stores/actions";
import { getErrorMessage } from "../errors/getErrorMessage";
import { authenticatedApi } from "../authenticatedAPI";
import {
  THREADS_TAG_TYPE,
  THREAD_RUN_ID_TAG_TYPE,
  THREAD_TAG_TYPE
} from "../cacheTagConstants";

import type { EntityState } from "@reduxjs/toolkit";
import {
  resetChatState,
  setActionButtonDisabled,
  setMessages,
  setThreadMessagesLoading
} from "../../features/pages/workstation/components/chat/chatbox/chatSlice";
import { threadsAdapter } from "../../stores/adapters/threadsAdapter";
import { RootState } from "../../stores/store";

export const threadsApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getThreadById: builder.query<TGetThreadMessagesResponse, string>({
      query: (threadId) => ({
        url: `thread/${threadId}`,
        method: "GET"
      }),
      providesTags: (result, error, threadId) => [
        { type: THREAD_TAG_TYPE, id: threadId }
      ],
      async onQueryStarted(threadId, { dispatch, queryFulfilled, getState }) {
        const response = await queryFulfilled;
        if (response.data.error) {
          dispatch(setErrorMessage(response.data.error));
        } else {
          const state = getState() as RootState;
          const chatMessages = state.chat.messages.filter(
            (message) => !message.isLastMessage && message.message
          );
          if (chatMessages.length !== response.data.result.messages.length) {
            dispatch(setMessages(response.data.result.messages));
          }
          dispatch(setThreadMessagesLoading(false));
          dispatch(setActionButtonDisabled(false));
        }
      }
    }),
    getThreadRunStepsById: builder.query<
      TGetThreadRunStepsByIdResponse,
      { threadId: string; runId: string }
    >({
      query: ({ threadId, runId }: { threadId: string; runId: string }) => ({
        url: `thread/${threadId}/runId/${runId}`,
        method: "GET"
      }),
      providesTags: (result, error, { threadId, runId }) => [
        { type: THREAD_RUN_ID_TAG_TYPE, id: `${threadId}-${runId}` }
      ]
    }),
    resetThread: builder.mutation<TResetThreadResponse, string>({
      query: (currentThread: string) => ({
        url: `resetThread/${currentThread}`,
        method: "POST"
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(resetChatState());
          dispatch(setActionButtonDisabled(true));
          const response = await queryFulfilled;
          if (response.data.error) {
            dispatch(setErrorMessage(response.data.error));
          }
          dispatch(setActionButtonDisabled(false));
        } catch (e) {
          dispatch(
            setErrorMessage(getErrorMessage(e, "Error resetting thread"))
          );
          dispatch(setActionButtonDisabled(false));
        }
      },
      invalidatesTags: [THREADS_TAG_TYPE]
    }),
    getUserThreads: builder.query<EntityState<TThreadReference, string>, void>({
      query: () => ({
        url: "userThreads",
        method: "GET"
      }),
      transformResponse: (response: GetUserThreadsResponse) => {
        return threadsAdapter.upsertMany(
          threadsAdapter.getInitialState(),
          response.threads
        );
      },
      providesTags: [THREADS_TAG_TYPE]
    }),
    abortThread: builder.mutation<TResetThreadResponse, string>({
      query: (threadId) => ({
        url: `abortThread/${threadId}`,
        method: "DELETE"
      }),
      async onQueryStarted(componentId, { dispatch, queryFulfilled }) {
        try {
          dispatch(setActionButtonDisabled(true));
          const response = await queryFulfilled;
          if (response.data.error) {
            dispatch(setErrorMessage(response.data.error));
          }
          dispatch(setThreadMessagesLoading(false));
          dispatch(setActionButtonDisabled(false));
        } catch {
          dispatch(setErrorMessage("Error aborting"));
          dispatch(setThreadMessagesLoading(false));
          dispatch(setActionButtonDisabled(false));
        }
      }
    })
  })
});

export const {
  useGetThreadByIdQuery,
  useResetThreadMutation,
  useGetUserThreadsQuery,
  useAbortThreadMutation,
  useGetThreadRunStepsByIdQuery
} = threadsApi;
