import {
  type TOpenAIPriceDictType,
  type TAnthropicPriceDictType,
  type TPerplexityPriceDictType,
  type SelectOption,
  anthropicPriceDict,
  perplexityPriceDict,
  openAIPriceDict
} from "@toolflow/shared";
import formatNumberToLocale from "../../../../../utilities/formatters/numbers/formatNumberToLocale";

export function getBaseModelOptions(
  priceDict:
    | TOpenAIPriceDictType
    | TAnthropicPriceDictType
    | TPerplexityPriceDictType
) {
  return Object.entries(priceDict).reduce((acc, [k, v]) => {
    acc.push({
      value: k,
      label: v.label,
      subText: `${formatNumberToLocale(v.tokenCount)} tokens`
    });
    return acc;
  }, [] as SelectOption[]);
}

export function getAnthropicModelOptions() {
  return getBaseModelOptions(anthropicPriceDict);
}

export function getPerplexityModelOptions() {
  return getBaseModelOptions(perplexityPriceDict);
}

export function getOpenAIModelOptions() {
  return getBaseModelOptions(openAIPriceDict);
}

export function getTextGenerationTechOptions() {
  return [...getAnthropicModelOptions(), ...getOpenAIModelOptions()];
}
