import { Content } from "@tiptap/core";
import { TThreadReference } from "../models";
import { Message } from "openai/resources/beta/threads/messages";
import OpenAI from "openai";
import { ToolCallsStepDetails } from "openai/resources/beta/threads/runs/steps";
import { UserInputDictType, UserInputResponseWithHandledError } from "../types";

export type TGetThreadRequest = {
  threadId: string;
};

export type TGetThreadParams = {
  threadId: string;
};

export type TGetThreadMessagesResponse = {
  result: { messages: TMessageByThreadId[] };
  success: boolean;
  error: string | null;
};

export type TOpenAIRunStep = OpenAI.Beta.Threads.Runs.RunStep;
export type TOpenAIToolRunStep = Omit<TOpenAIRunStep, "step_details"> & {
  step_details: ToolCallsStepDetails;
};

export type TRunStep = Omit<TOpenAIToolRunStep, "step_details"> & {
  step_details: ToolCallsStepDetails & {
    transformedToolCalls: {
      toolId?: string;
      inputs: UserInputDictType;
      outputs: UserInputResponseWithHandledError;
    }[];
  };
};

export type TGetThreadRunStepsByIdResponse = {
  result: { runSteps: TRunStep[] };
  success: boolean;
  error: string | null;
};

export enum EMessageSocketComponentId {
  ASSISTANT = "assistant"
}

export enum EMessageSocketType {
  CREATE_MESSAGE = "createMessage",
  UPDATE_LOADING_STATUS = "updateLoadingStatus",
  UPDATE_MESSAGE = "updateMessage",
  COMPLETED_MESSAGE = "completedMessage"
}

export enum EMessageRole {
  USER = "user",
  ASSISTANT = "assistant",
  SYSTEM = "system"
}

export enum EMessageStatus {
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  INCOMPLETE = "incomplete"
}

export type TOpenAIMessageSettings = {
  metadata?: Record<string, string>;
  status: EMessageStatus;
  incompleteDetails: Message.IncompleteDetails | null;
  runId: string | null;
  toolCallsStatus?: string[];
};

export type TToolDetails = {
  inputs: UserInputDictType;
  outputs: UserInputResponseWithHandledError;
  toolId: string;
};

export type MessageSettings = TOpenAIMessageSettings & {
  toolDetails?: TToolDetails[];
};

export type TMessageByThreadId = {
  role: EMessageRole;
  message: string;
  id: string;
  isLastMessage?: boolean;
  settings: MessageSettings;
};

export type TTransformedMessage = Omit<TMessageByThreadId, "message"> & {
  content: Content;
};

export type TResetThreadResponse = {
  result: { threadId: string };
  success: boolean;
  error: string | null;
};
export type TAbortThreadResponse = {
  result: { message: string };
  success: boolean;
  error: string | null;
};

export type GetUserThreadsResponse = {
  threads: TThreadReference[];
};
